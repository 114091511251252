import React, { useState } from 'react';
import styles from './ComponentItemModal.module.scss';
import { Modal } from 'tt-ui-kit';
import { Button, FormControl, TextField } from '@mui/material';

const ComponentItemModal = ({ visible, onClose, setItemComponents, index }) => {
  const [formData, setFormData] = useState('');

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const handleAdd = () => {
    if (formData.trim() !== '') {
      const newItem = {
        id: Date.now(),
        name: formData.trim(),
      };
      setItemComponents(newItem);
      setFormData('');
      onClose();
    }
  };

  return (
    <Modal
      open={visible}
      onClose={onClose}
      title="Add new component/item"
      className={styles.modalContainer}
    >
      <div className={styles.modalContent}>
        <FormControl fullWidth>
          <TextField
            label="Component/item name"
            value={formData}
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': { paddingLeft: '0px' },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #EFEFEF',
                borderRadius: '12px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#EFEFEF' },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#EFEFEF' },
            }}
          />
        </FormControl>
        <div className={styles.buttonContainer}>
          <Button variant="outlined" onClick={onClose}>
            CANCEL
          </Button>
          <Button variant="contained" onClick={handleAdd}>
            Add new
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ComponentItemModal;
