import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './Supplier.module.scss';
import { Modal, SendIcon } from 'tt-ui-kit';
import {
  Button,
  FormControl,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';

const SupplierModal = ({ visible, onClose, setSuppliers }) => {
  const [withInvitation, setWithInvitation] = useState(false);
  const [formData, setFormData] = useState({
    id: Date.now(),
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '',
    comment: '',
    note: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleAdd = () => {
    if (formData !== '') {
      setSuppliers(formData);
      setFormData('');
      onClose();
    }
  };

  return (
    <Modal open={visible} onClose={onClose} title="Add supplier " className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <Typography variant="body2" className={styles.modalDescription}>
          Please complete all fields to add a supplier. If you want to invite the supplier to the
          TransparanTerra platform, please check the box &quot;Add with Invitation.&quot; You can
          also write a message to help your colleague accept the invitation and confirm the
          item/component information.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={withInvitation}
              onChange={(e) => setWithInvitation(e.target.checked)}
              color="primary"
            />
          }
          label="Add with Invitation"
          className={styles.checkbox}
        />
        <FormControl fullWidth className={styles.formContainer}>
          <div className={styles.inputRow}>
            <TextField
              label="First name*"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={styles.inputField}
            />
            <TextField
              label="Last name*"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={styles.inputField}
            />
          </div>

          <TextField
            label="Company name"
            name="company"
            value={formData.company}
            onChange={handleChange}
            fullWidth
            className={styles.inputField}
          />

          <div className={styles.inputRow}>
            <TextField
              label="Email*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={styles.inputField}
            />
            <ReactPhoneInput
              country="us"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phoneNumber',
                required: true,
              }}
              placeholder="Phone number"
              buttonClass={styles.phone}
              inputClass={styles.phoneInput}
            />
          </div>
          {withInvitation && (
            <TextField
              label="Comment for the invitee"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              className={styles.inputField}
            />
          )}

          <TextField
            label="Note for me"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            className={styles.inputField}
          />
        </FormControl>
        {/* {!withInvitation && (
          <Alert severity="warning" className={styles.alert}>
            <Typography variant="body1" sx={{ color: '#212121' }}>
              This user is already signed in on the platform.You can add them to your contacts list.
            </Typography>
          </Alert>
        )} */}
        <div className={styles.buttonContainer}>
          <Button variant="outlined" onClick={onClose}>
            CANCEL
          </Button>
          <Button className={styles.modal_buttonSend} variant="contained" onClick={handleAdd}>
            {!withInvitation ? (
              'Add'
            ) : (
              <>
                <SendIcon className={styles.sendIcon} />
                SEND
              </>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SupplierModal;
